import moment from 'moment';

const date = new Date();
export const year = date.getUTCFullYear();

export const startDate = moment().startOf('year').format('YYYY-MM-DD');
export const endDate = moment().format('YYYY-MM-DD');

export const periodAsQueryParam = (
  date: Record<string, number | string | null>,
) => {
  const dateToArray = Object.values(date).filter(Boolean) as number[];
  return moment(dateToArray).format(
    'yyyy-MM' + (dateToArray.length > 2 ? '-DD' : ''),
  );
};
