import { BarChartItem } from '@/types/charts';
import { Value } from '@/components/ui/text/UiValue.vue';

export interface taxPayerGraphSection {
  totalSumIncome: number;
  sumIncome: number;
  sumPartnerIncome: number;
  percentPartnerIncome: number;
  section: number;
}

export interface partnersGraphSection {
  partnersIncome: Array<{ sumIncome: number; commercialName: string }>;
  percentIncome: number;
  section: number;
}

export function taxpayerGraphParse(
  graphData: taxPayerGraphSection[],
): BarChartItem[] {
  const sumIncome: BarChartItem = {
    name: 'Доходы всего',
    values: [],
  };
  const sumPartnerIncome: BarChartItem = {
    name: 'Доходы от партнеров',
    values: [],
  };
  graphData.forEach((section) => {
    sumIncome.values.push(section.sumIncome);
    sumPartnerIncome.values.push(section.sumPartnerIncome);
  });

  return [{ ...sumIncome }, { ...sumPartnerIncome }];
}

export function partnersGraphParse(
  graphData: partnersGraphSection[],
): BarChartItem[] {
  const parsedIncomes: Record<string, number[]> = {};

  graphData.forEach(({ partnersIncome }) =>
    partnersIncome.forEach(({ commercialName, sumIncome }) => {
      if (!parsedIncomes[commercialName]) {
        parsedIncomes[commercialName] = [];
        parsedIncomes[commercialName].push(sumIncome);
      } else {
        parsedIncomes[commercialName].push(sumIncome);
      }
    }),
  );

  return (Object.entries(parsedIncomes) as Array<[string, number[]]>).map(
    ([name, values]) => ({
      name,
      values,
    }),
  );
}

export const donutValuesToArray = (ctx: Value[]) => {
  const result: number[] = [];
  ctx?.forEach((item) => result.push(item.value ? +item.value : 0));
  return result;
};

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const downloadBlobFromBuffer = async (
  filename: string,
  base64?: string,
) => {
  if (!base64) return;
  const blob = await fetch(base64).then((res) => res.blob());

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
