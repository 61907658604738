import { RouteConfig } from 'vue-router';

const SelfEmployeeView = () => import('@/views/self-employee.vue');
const SelfEmployeeViewIndex = () => import('@/views/self-employee/index.vue');
const DetailsSelfemployeePartnersIdView = () =>
  import('@/views/self-employee/partners/_tin.vue');

const TaxPayersView = () => import('@/views/self-employee/tax-payers.vue');
const TaxPayersViewIndex = () =>
  import('@/views/self-employee/tax-payers/index.vue');

const PartnersView = () => import('@/views/self-employee/partners.vue');
const PartnersViewIndex = () =>
  import('@/views/self-employee/partners/index.vue');

const InfoView = () => import('@/views/self-employee/info/info.vue');
const InfoViewIndex = () => import('@/views/self-employee/info/index.vue');

const DetailsSelfemployeeTaxpayersIdView = () =>
  import('@/views/self-employee/tax-payers/_tin/index.vue');
const DetailsSelfemployeeTaxpayersIdInfoView = () =>
  import('@/views/self-employee/tax-payers/_tin/info/info.vue');
const DetailsSelfemployeeTaxpayersIdInfoIndex = () =>
  import('@/views/self-employee/tax-payers/_tin/info/index.vue');
const DetailsSelfemployeeTaxpayersIdInfoIncomeView = () =>
  import('@/views/self-employee/tax-payers/_tin/info/income.vue');
const DetailsSelfemployeeTaxpayersIdInfoTaxesView = () =>
  import('@/views/self-employee/tax-payers/_tin/info/taxes/index.vue');
const DetailsSelfemployeeTaxpayersIdInfoTaxesReceiptsView = () =>
  import('@/views/self-employee/tax-payers/_tin/info/taxes/receipts.vue');
const DetailsSelfemployeeTaxpayersIdPartnersView = () =>
  import('@/views/self-employee/tax-payers/_tin/partners/partners.vue');
const DetailsSelfemployeeTaxpayersIdPartnersIndex = () =>
  import('@/views/self-employee/tax-payers/_tin/partners/index.vue');
const DetailsSelfemployeeTaxpayersIdPartnersIncomeView = () =>
  import('@/views/self-employee/tax-payers/_tin/partners/income.vue');
const DetailsSelfemployeeTaxpayersIdPartnersTaxesView = () =>
  import('@/views/self-employee/tax-payers/_tin/partners/taxes/index.vue');
const DetailsSelfemployeeTaxpayersIdPartnersTaxesReceiptsView = () =>
  import('@/views/self-employee/tax-payers/_tin/partners/taxes/receipts.vue');

export default {
  path: '/self-employee',
  component: SelfEmployeeView,
  meta: {
    name: 'page.selfEmployee',
  },
  children: [
    {
      path: '',
      name: 'self-employee-index',
      redirect: { name: 'self-employee-taxpayers-index' },
      component: SelfEmployeeViewIndex,
    },
    {
      path: 'taxpayers',
      component: TaxPayersView,
      meta: {
        name: 'section.taxPayers',
      },
      children: [
        {
          path: '',
          name: 'self-employee-taxpayers-index',
          component: TaxPayersViewIndex,
        },
        {
          path: ':tin',
          name: 'details-self-employee-taxpayers-tin',
          redirect: { name: 'details-self-employee-taxpayers-tin-info' },
          component: DetailsSelfemployeeTaxpayersIdView,
          meta: {
            name: 'section.taxPayers',
          },
          children: [
            {
              path: 'info',
              component: DetailsSelfemployeeTaxpayersIdInfoView,
              name: 'details-self-employee-taxpayers-tin-info-view',
              redirect: { name: 'details-self-employee-taxpayers-tin-info' },
              children: [
                {
                  path: 'index',
                  name: 'details-self-employee-taxpayers-tin-info',
                  component: DetailsSelfemployeeTaxpayersIdInfoIndex,
                },
                {
                  path: 'income',
                  name: 'details-self-employee-taxpayers-tin-info-income',
                  component: DetailsSelfemployeeTaxpayersIdInfoIncomeView,
                },
                {
                  path: 'taxes',
                  name: 'details-self-employee-taxpayers-tin-info-taxes',
                  component: DetailsSelfemployeeTaxpayersIdInfoTaxesView,
                },
                {
                  path: 'receipts',
                  name: 'details-self-employee-taxpayers-tin-info-taxes-receipts',
                  component:
                    DetailsSelfemployeeTaxpayersIdInfoTaxesReceiptsView,
                },
              ],
            },
            {
              path: 'partners',
              component: DetailsSelfemployeeTaxpayersIdPartnersView,
              name: 'details-self-employee-taxpayers-tin-partners-view',
              redirect: {
                name: 'details-self-employee-taxpayers-tin-partners',
              },
              children: [
                {
                  path: 'index',
                  name: 'details-self-employee-taxpayers-tin-partners',
                  component: DetailsSelfemployeeTaxpayersIdPartnersIndex,
                },
                {
                  path: ':partnerId/income',
                  name: 'details-self-employee-taxpayers-tin-partners-income',
                  component: DetailsSelfemployeeTaxpayersIdPartnersIncomeView,
                },
                {
                  path: ':partnerId/taxes',
                  name: 'details-self-employee-taxpayers-tin-partners-taxes',
                  component: DetailsSelfemployeeTaxpayersIdPartnersTaxesView,
                },
                {
                  path: ':partnerId/taxes-receipts',
                  name: 'details-self-employee-taxpayers-tin-partners-taxes-receipts',
                  component:
                    DetailsSelfemployeeTaxpayersIdPartnersTaxesReceiptsView,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'partners',
      component: PartnersView,
      meta: {
        name: 'section.partners',
      },
      children: [
        {
          path: '',
          name: 'self-employee-partners-index',
          component: PartnersViewIndex,
        },
        {
          path: ':tin',
          name: 'details-self-employee-partners-tin',
          component: DetailsSelfemployeePartnersIdView,
          meta: {
            name: 'section.partners',
          },
        },
      ],
    },
    {
      path: 'info',
      component: InfoView,
      meta: {
        name: 'section.info',
      },
      children: [
        {
          path: '',
          name: 'self-employee-info-index',
          component: InfoViewIndex,
        },
      ],
    },
  ],
} as RouteConfig;
