import ListStore from '@/store/core/ListStore';
import { action, computed } from 'mobx';
import services from '@/api/services';
import { partnersGraphParse, partnersGraphSection } from '@/utils/parser';
import { BarChartItem } from '@/types/charts';
import data from '@/mock/partners/partnersYearIncome.json';

export default class PartnersIncomeYearStore extends ListStore<
  partnersGraphSection[]
> {
  @computed get processedData(): BarChartItem[] | undefined {
    if (!this.data) return;
    return partnersGraphParse(this.data);
  }

  @action.bound async fetch(id: string, period: string) {
    this.isFetching = true;
    try {
      this.data = data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
