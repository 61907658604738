import ListStore from '@/store/core/ListStore';
import { action, computed, override } from 'mobx';
import services from '@/api/services';
import { Summary, TranslatedString } from '@/store/types';
import { Value } from '@/components/ui/text/UiValue.vue';
import { i18n } from '@/i18n';
import { filterFalsy } from '@/utils/filters';

type ChartData = {
  label: TranslatedString;
  content: Value[];
  rawValues?: boolean;
};

export default class TaxpayerSummaryStore extends ListStore<Summary> {
  @override filters = {
    from: undefined,
    to: undefined,
  };
  @computed get processedData():
    | Record<string, ChartData | ChartData[]>
    | undefined {
    if (!this.data) return;
    const { partners, revenues, taxes, receipts, ...counts } = this.data;
    return {
      count: {
        label: i18n.t('summary.payersCount'),
        rawValues: true,
        content: [
          {
            title: i18n.t('summary.totalCount'),
            value: counts.totalSelfEmployed,
            badge: '#9FB8EB',
          },
          {
            title: i18n.t('summary.ip'),
            value: counts.ylCount,
            percents: counts.ylCountPercent,
            badge: '#FFDE2F',
          },
          {
            title: i18n.t('summary.fl'),
            value: counts.flCount,
            percents: counts.flCountPercent,
            badge: '#006EBE',
          },
        ],
      },
      stats: [
        {
          label: i18n.t('summary.revenues'),
          content: [
            {
              title: i18n.t('summary.totalRevenues'),
              value: revenues.totalRevenue,
              badge: '#9FB8EB',
            },
            {
              title: i18n.t('summary.ipRevenues'),
              value: revenues.revenueYl,
              percents: revenues.revenueYlPercent,
              badge: '#FFDE2F',
            },
            {
              title: i18n.t('summary.flRevenues'),
              value: revenues.revenueFl,
              percents: revenues.revenueFlPercent,
              badge: '#006EBE',
            },
          ],
        },
        {
          label: i18n.t('summary.taxes'),
          content: [
            {
              title: i18n.t('summary.totalTax'),
              value: taxes.totalTax,
              badge: '#9FB8EB',
            },
            {
              title: i18n.t('summary.ipTaxes'),
              value: taxes.taxYl,
              percents: taxes.taxYlPercent,
              badge: '#FFDE2F',
            },
            {
              title: i18n.t('summary.flTaxes'),
              value: taxes.taxFl,
              percents: taxes.taxFlPercent,
              badge: '#006EBE',
            },
          ],
        },
        {
          label: i18n.t('summary.receipts'),
          rawValues: true,
          content: [
            {
              title: i18n.t('summary.totalCountReceipts'),
              value: receipts.totalCountPerMonth,
              badge: '#9FB8EB',
            },
            {
              title: i18n.t('summary.refundReceipts'),
              value: receipts.annulledCountPerMonth,
              percents: receipts.annulledCountPerMonthPercent,
              badge: '#FFDE2F',
            },
            {
              title: i18n.t('summary.saleReceipts'),
              value: receipts.activeCountPerMonth,
              percents: receipts.activeCountPerMonthPercent,
              badge: '#006EBE',
            },
          ],
        },
        {
          label: i18n.t('summary.partners'),
          rawValues: true,
          content: [
            {
              title: i18n.t('summary.totalCountPartners'),
              value: partners.totalCount,
              badge: '#9FB8EB',
            },
            {
              title: i18n.t('summary.platforms'),
              value: partners.partnersCount,
              percents: partners.partnersCountPercent,
              badge: '#FFDE2F',
            },
            {
              title: i18n.t('summary.banks'),
              value: partners.banksCount,
              percents: partners.banksCountPercent,
              badge: '#006EBE',
            },
          ],
        },
      ],
    };
  }

  @action.bound async fetch() {
    this.isFetching = true;
    try {
      this.data = (
        await services.selfEmployee.getSummary(filterFalsy(this.filters))
      ).data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
