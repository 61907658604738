import { computed, observable } from 'mobx';
import { year } from '@/utils/date';
import moment from 'moment';

export default class Control {
  @observable year = String(year);

  @computed get isDisabledDate() {
    return {
      disabledBefore: moment(this.year)
        .subtract(1, 'year')
        .endOf('year')
        .toDate(),
      disabledAfter: moment(this.year).add(1, 'year').startOf('year').toDate(),
    };
  }
}
