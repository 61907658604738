import ListStore from '@/store/core/ListStore';
import { action, computed, override } from 'mobx';
import services from '@/api/services';
import { Partner } from '@/store/types';
import { numGroup } from '@/utils/numbers';
import data from '@/mock/taxpayers/partners.json';

export default class PartnerStore extends ListStore<{ items: Partner[] }> {
  @override filters = {
    id: '',
    year: '',
  };

  @computed get processedData() {
    if (!this.data?.items) return { items: [] };
    const items = this.data.items.map((item: Partner) => ({
      ...item,
      sumTax: numGroup(item.sumTax),
      sumIncome: numGroup(item.sumIncome),
    }));

    return { items };
  }

  @action.bound async fetch() {
    this.isFetching = true;
    try {
      this.data = { items: data.partners };
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
