import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/routes/index';
import stores from '@/store/core/RootStore';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.key) {
    localStorage.setItem('demoKey', to.query.key as string);
    stores.userStore.demoKey = localStorage.getItem('demoKey');
  }
  if (
    stores.userStore.demoKey !== 'hxygKbvT' &&
    to.name !== 'auth-index'
    // && !window.location.href.includes('localhost')
  ) {
    next({ name: 'auth-index' });
  } else {
    next();
  }
});

export default router;
