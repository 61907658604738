import Vue from 'vue';

Vue.filter('numGroup', (value: string | number | null): string => {
  if (value === null) return '-';
  if (typeof value !== 'number') return value;
  return value
    ?.toString()
    .split(/(?=(?:...)*$)/)
    .join(' ');
});
