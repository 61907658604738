import ListStore from '@/store/core/ListStore';
import { action, computed } from 'mobx';
import services from '@/api/services';
import { numGroup } from '@/utils/numbers';
import { Details } from '@/store/types';

export default class TaxpayerDetailsStore extends ListStore<Details> {
  @computed get processedData() {
    if (!this.data) return;

    const totalPayedPercents = numGroup(
      (this.data.totalPayed / this.data.totalAccrued || 0) * 100,
    );

    const computedDebt = numGroup(
      this.data.totalAccrued - this.data.totalPayed,
    );

    const computedDebtPercents = numGroup(
      ((this.data.totalAccrued - this.data.totalPayed) /
        this.data.totalAccrued || 0) * 100,
    );

    return {
      ...this.data,
      totalAccrued: numGroup(this.data.totalAccrued),
      totalPayed: numGroup(this.data.totalPayed),
      ...(!!totalPayedPercents && {
        totalPayedPercents,
      }),
      computedDebt,
      ...(!!computedDebtPercents && { computedDebtPercents }),
    };
  }

  @action.bound async fetch(tin: string) {
    this.isFetching = true;
    try {
      this.data = (await services.selfEmployee.getDetailsByTin(tin)).data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
