import ListStore from '@/store/core/ListStore';
import { action, computed, observable, override } from 'mobx';
import services from '@/api/services';
import { Receipt } from '@/store/types';
import { TaxBaseParams } from '@/api/services/types';
import { dateFormat, filterFalsy } from '@/utils/filters';
import { numGroup } from '@/utils/numbers';
import { Pageable, PageableQueryParams } from '@/types/pagination';
import { computeReceiptOperationType } from '@/store/mapping';
import moment from 'moment/moment';
import { i18n } from '@/i18n';

export default class TaxpayerTaxBaseStore extends ListStore<
  Pageable<Receipt[]> & { totalSum: number }
> {
  @override pageable: PageableQueryParams = {
    size: 20,
    direction: 'desc',
    page: 1,
    sortBy: 'date',
  };

  @override filters = {
    date: new Date(),
    tin: '',
  };
  @observable monthTaxes: string | null = null;

  @computed get processedData() {
    if (!this.data) return [];
    return this.data.data.map((ticket) => {
      const computedType = computeReceiptOperationType(ticket.operationType);

      return {
        ...ticket,
        ticketNumber: `${ticket.fm}-${ticket.fd}`,
        date: dateFormat(ticket.date, {
          format: 'DD.MM.YYYY',
          addHours: 0,
        }),
        sum: numGroup(ticket.sum),
        operationTypeLabel: i18n.t(computedType.label),
        operationTypeColor: computedType.color,
      };
    });
  }

  @action.bound async getMonthTaxes(params: TaxBaseParams) {
    try {
      this.monthTaxes = numGroup(
        (await services.selfEmployee.getMonthTaxesByTin(params)).data.taxTotal,
      );
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound async fetch() {
    this.isFetching = true;
    try {
      const params: TaxBaseParams = {
        year: moment(this.filters.date).year(),
        month: moment(this.filters.date).month() + 1,
        tin: this.filters.tin,
      };

      this.data = (
        await services.selfEmployee.getTaxBase({
          ...filterFalsy(params),
          ...filterFalsy(this.pageable),
        })
      ).data;

      await this.getMonthTaxes(params);
    } catch (e) {
      console.error(e);
      this.clearData();
    } finally {
      this.isFetching = false;
    }
  }
}
