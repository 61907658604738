import UserStore from '@/store/user';
import ControlStore from '@/store/core/Control';
import TaxpayersListStore from '@/store/self-employee/taxpayersList';
import PartnersListStore from '@/store/self-employee/partnersList';
import PartnerCardStore from '@/store/self-employee/partnerCard';
import TaxpayerProfileStore from '@/store/self-employee/taxpayerProfile';
import TaxpayerDetailsStore from '@/store/self-employee/taxpayerDetails';
import TaxpayerPartnersStore from '@/store/self-employee/taxpayerPartners';
import TaxpayerReceiptsStore from '@/store/self-employee/taxpayerReceipts';
import PartnersIncomeMonthStore from '@/store/self-employee/partnersIncomeMonth';
import PartnersIncomeYearStore from '@/store/self-employee/partnersIncomeYear';
import TaxpayersControlStore from '@/store/self-employee/taxpayersControl';
import PartnersControlStore from '@/store/self-employee/partnersControl';
import TaxpayerReceiptDetailsStore from '@/store/self-employee/taxpayerReceiptDetails';
import TaxpayerTaxBaseStore from '@/store/self-employee/taxpayerTaxBase';
import TaxpayerIncomeBaseStore from '@/store/self-employee/taxpayerIncomeBase';
import SummaryStore from '@/store/self-employee/taxpayerSummary';
import TaxpayerMonthStatistic from '@/store/self-employee/taxpayerYearStatistic';

export class RootStores {
  userStore = new UserStore(this);
  controlStore = new ControlStore();

  selfEmployeeTaxpayersControl = new TaxpayersControlStore();
  selfEmployeePartnersControl = new PartnersControlStore();

  selfEmployeeTaxpayersListStore = new TaxpayersListStore(this);
  selfEmployeeTaxpayerProfileStore = new TaxpayerProfileStore(this);
  selfEmployeeTaxpayerDetailsStore = new TaxpayerDetailsStore(this);
  selfEmployeeTaxpayerPartnersStore = new TaxpayerPartnersStore(this);
  selfEmployeeTaxpayerReceiptsStore = new TaxpayerReceiptsStore(this);
  selfEmployeeTaxpayerReceiptDetailsStore = new TaxpayerReceiptDetailsStore(
    this,
  );
  selfEmployeeTaxpayerTaxBaseStore = new TaxpayerTaxBaseStore(this);
  selfEmployeeTaxpayerIncomeBaseStore = new TaxpayerIncomeBaseStore(this);
  selfEmployeeTaxpayerSummaryStore = new SummaryStore(this);
  selfEmployeeTaxpayerYearStatisticStore = new TaxpayerMonthStatistic(this);

  selfEmployeePartnerListStore = new PartnersListStore(this);
  selfEmployeePartnerCardStore = new PartnerCardStore(this);
  selfEmployeePartnerIncomeMonthStore = new PartnersIncomeMonthStore(this);
  selfEmployeePartnerIncomeYearStore = new PartnersIncomeYearStore(this);
}

export default new RootStores();
