import { RouteConfig } from 'vue-router';

const ControlView = () => import('@/views/control.vue');
const ControlViewIndex = () => import('@/views/control/index.vue');

export default {
  path: '/control',
  component: ControlView,
  meta: {
    name: 'page.control',
  },
  children: [
    {
      path: '',
      name: 'control-index',
      component: ControlViewIndex,
    },
  ],
} as RouteConfig;
