import ListStore from '@/store/core/ListStore';
import { action, computed, override } from 'mobx';
import services from '@/api/services';
import { dateFormat } from '@/utils/filters';
import { numGroup } from '@/utils/numbers';
import { computeReceiptOperationType } from '@/store/mapping';
import moment from 'moment';
import { Pageable, PageableQueryParams } from '@/types/pagination';
import { IncomeBaseReceiptParams } from '@/api/services/types';
import { IncomeBaseReceiptItem } from '@/store/types';
import { i18n } from '@/i18n';

export default class TaxpayerIncomeBaseStore extends ListStore<
  Pageable<IncomeBaseReceiptItem[] & { totalSum: number }>
> {
  @override pageable: PageableQueryParams = {
    size: 20,
    direction: 'desc',
    page: 1,
    sortBy: 'date',
  };

  @override filters = {
    from: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    to: moment(new Date()).endOf('month').format('YYYY-MM-DD'),
    offset: 0,
    limit: 20,
    tin: '',
    operationType: null,
  };

  @computed get processedData() {
    if (!this.data?.data.length) return [];
    return this.data.data.map((ticket) => {
      const computedType = computeReceiptOperationType(ticket.operationType);

      return {
        ...ticket,
        ticketNumber: `${ticket.fmNumber}-${ticket.fdNumber}`,
        date: dateFormat(ticket.dateTime, {
          format: 'DD.MM.YYYY',
          addHours: 0,
        }),
        sum: numGroup(ticket.ticketTotalSum),
        operationTypeLabel: i18n.t(computedType.label),
        operationTypeColor: computedType.color,
      };
    });
  }

  @action.bound setDate(date: Date) {
    this.setFilter({
      from: moment(date).startOf('month').format('YYYY-MM-DD'),
      to: moment(date).endOf('month').format('YYYY-MM-DD'),
    });
  }

  @action.bound async fetch(fromTins: string[] = []) {
    this.isFetching = true;
    try {
      const params: PageableQueryParams<IncomeBaseReceiptParams> = {
        from: this.filters.from,
        to: this.filters.to,
        tin: this.filters.tin,
        operationType: this.filters.operationType,
        offset: ((this.pageable.page || 1) - 1) * (this.pageable.size || 20),
        limit: this.pageable.size,
        direction: this.pageable.direction,
        sortBy: this.pageable.sortBy,
        fromTins,
      };

      this.data = (
        await services.selfEmployee.getIncomeBaseReceipts(params)
      ).data;
    } catch (e) {
      this.clearData();
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
