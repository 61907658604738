import ListStore from '@/store/core/ListStore';
import { action, computed, override } from 'mobx';
import { startDate, endDate } from '@/utils/date';
import { Taxpayers } from '@/store/types';
import { dateFormat } from '@/utils/filters';
import { numGroup } from '@/utils/numbers';

import {
  computeSettingType,
  computeStatus,
  computeType,
} from '@/store/mapping';
import services from '@/api/services';
import { TaxpayersFilters } from '@/api/services/types';
import { Pageable, PageableQueryParams } from '@/types/pagination';
import { i18n } from '@/i18n';

export default class TaxpayersStore extends ListStore<Pageable<Taxpayers[]>> {
  @override pageable: PageableQueryParams = {
    size: 20,
    direction: 'desc',
    page: 1,
    sortBy: 'setDate',
  };

  initialFilters: TaxpayersFilters = {
    year: new Date().getFullYear(),
    periodStart: startDate,
    periodEnd: endDate,
    taxMode: 'ESP',
    month: null,
    tinOrName: '',
    type: null,
    status: null,
    setDate: undefined,
    settingType: null,
    withExcess: null,
    withDebt: null,
  };

  @override
  filters: TaxpayersFilters = { ...this.initialFilters };

  @computed get processedData() {
    return (
      this.data?.data?.map((item: Taxpayers) => {
        const computedType = computeType(item.type);
        const computedStatus = computeStatus(item.status);
        const computedSettingType = computeSettingType(item.settingType);

        return {
          ...item,
          setDate: dateFormat(item.setDate, {
            format: 'DD.MM.YYYY',
            addHours: 0,
          }),
          incomePeriod: numGroup(item.incomePeriod),
          incomeYear: numGroup(item.incomeYear),
          taxYear: numGroup(item.taxYear),
          debt: numGroup(item.debt),
          settingType: i18n.t(computedSettingType?.label),
          typeLabel: i18n.t(computedType?.label),
          typeColor: computedType?.color,
          statusLabel: i18n.t(computedStatus?.label),
          statusColor: computedStatus?.color,
        };
      }) || []
    );
  }

  @action.bound async fetch() {
    this.isFetching = true;
    try {
      this.data = (
        await services.selfEmployee.getTaxpayersStatistics(this.notFalsyParams)
      ).data;
    } catch (e) {
      console.error(e);
      this.clearData();
    } finally {
      this.isFetching = false;
    }
  }
}
