import ListStore from '@/store/core/ListStore';
import { action } from 'mobx';
import services from '@/api/services';
import { ReceiptParams } from '@/api/services/types';

export default class TaxpayerReceiptDetailsStore extends ListStore<string> {
  @action.bound async fetch(params: ReceiptParams) {
    this.isFetching = true;
    try {
      const blob = (await services.selfEmployee.getReceiptDetails(params)).data;
      this.data = URL.createObjectURL(blob);
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }

  @action.bound async resetData(): Promise<void> {
    this.data = undefined;
  }
}
