type NumGroupConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: NumGroupConfig = {
  incorrectSign: '0',
  fraction: true,
  fractionDigits: 2,
};

export function numGroup(
  value: number | null | undefined | '',
  options?: NumGroupConfig,
) {
  const opts = Object.assign(
    { ...defaultConfig },
    options || {},
  ) as Required<NumGroupConfig>;
  if (value === null || value === undefined || value === '' || isNaN(value))
    return opts.incorrectSign;
  return Number(value.toFixed(2)).toLocaleString(undefined, {
    ...(opts.fraction && {
      minimumFractionDigits: opts.fractionDigits,
      maximumFractionDigits: opts.fractionDigits,
    }),
  });
}
function rnd(n: number, precision: number) {
  const prec = 10 ** precision;
  return Math.round(n * prec) / prec;
}

export function getFriendlyNum(n: number) {
  const abbrev = ['тыс.', 'млн.'];
  let base = Math.floor(Math.log(Math.abs(n)) / Math.log(1000));
  const suffix = abbrev[Math.min(abbrev.length - 1, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? rnd(n / 1000 ** base, 2) + ' ' + suffix : '' + n;
}
