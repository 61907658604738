import { wrap } from '@/utils/endpoints';

const ENDPOINTS = wrap('', {
  auth: {
    index: '',
    refresh: '/refresh',
  },
  account: {
    taxes: {
      index: '',
    },
    monthActivity: {
      index: '',
    },
  },
  findCashVouchers: {
    index: '',
  },
  analytics: {
    index: '',
    legalPersonStatistics: '/legalPersonStatistics',
    incomeAndTax: '/incomeAndTax',
    getTaxBase: '/getTaxBaseTickets',
    id: (id) => `/${id}`,
  },
  print: {
    getReceipt: '/getSelfEmploymentReceipt',
  },
  partners: {
    index: '',
  },
  receipts: {
    id: (id) => `/${id}`,
    income: {
      id: (id) => `/${id}`,
    },
  },
  stats: {
    getProfileByTin: (tin) => `/se/${tin}`,
    getPartnersList: '/partners',
    getPartnersListByTin: '/sePartners',
    getPartnerCard: (tin) => `/partner/${tin}`,
  },
  legal_person: {
    aggregate: '/aggregate/selfEmployedSummary',
  },
  users: {
    index: '',
  },
});

export default ENDPOINTS;
