import { Taxpayers, Partners } from '@/store/types';

export const taxpayersStatuses: {
  value: Taxpayers['status'];
  label: string;
  color?: string;
}[] = [
  { value: 'ACTIVE', label: 'types.active', color: 'success' },
  { value: 'INACTIVE', label: 'types.inactive', color: 'danger' },
];

export const taxpayersBooleanStatuses: {
  value: boolean;
  label: string;
  color?: string;
}[] = [
  { value: true, label: 'types.active', color: 'success' },
  { value: false, label: 'types.inactive', color: 'danger' },
];

export const taxpayersOperationTypes: {
  value: string;
  label: string;
  color?: string;
}[] = [
  { value: 'INCOME', label: 'types.sale', color: 'success' },
  { value: 'INCOME_RETURN', label: 'types.returnSale', color: 'danger' },
];

export const taxpayersTypes: {
  value: Taxpayers['type'];
  label: string;
  color?: string;
}[] = [
  { value: 'FL', label: 'types.fl', color: 'info' },
  { value: 'IP', label: 'types.ip', color: 'success' },
  // { value: 'UL', label: 'types.yl', color: 'neutral' },
];

export const partnerTypes: {
  value: Partners['type'];
  label: string;
  color: string;
}[] = [
  { value: 'PARTNER', label: 'types.platform', color: 'info' },
  { value: 'BANK', label: 'types.bank', color: 'neutral' },
];

export const periodTypes = [
  { label: 'За день', value: 'date' },
  { label: 'За месяц', value: 'month' },
];

export const receiptStatuses = [
  { value: 'UNPAID', label: 'Неоплачен', color: 'danger' },
  { value: 'PAID', label: 'Оплачен', color: 'success' },
];

export const settingTypes = [
  { value: 'SELF', label: 'types.self' },
  { value: 'PARTNER', label: 'types.platform' },
];

export const receiptsBooleanStatuses: {
  value: boolean;
  label: string;
  color?: string;
}[] = [
  { value: true, label: 'types.active', color: 'success' },
  { value: false, label: 'types.annulling', color: 'danger' },
];

const UNDEFINED_STATE = {
  value: undefined,
  label: 'types.undefined',
  color: 'neutral',
};

export const computeStatus = (
  status?: typeof taxpayersStatuses[number]['value'],
) => taxpayersStatuses.find(({ value }) => value === status) || UNDEFINED_STATE;

export const computeBooleanStatus = (status?: boolean) =>
  taxpayersBooleanStatuses.find(({ value }) => value === !!status) ||
  UNDEFINED_STATE;

export const computeType = (type?: typeof taxpayersTypes[number]['value']) =>
  taxpayersTypes.find(({ value }) => value === type) || UNDEFINED_STATE;

export const computeReceiptStatus = (
  status?: typeof receiptStatuses[number]['value'],
) => receiptStatuses.find(({ value }) => value === status) || UNDEFINED_STATE;

export const computeSettingType = (
  type?: typeof settingTypes[number]['value'],
) => settingTypes.find(({ value }) => value === type) || UNDEFINED_STATE;

export const computePartnerType = (
  type?: typeof partnerTypes[number]['value'],
) => partnerTypes.find(({ value }) => value === type) || UNDEFINED_STATE;

export const computeBooleanReceiptStatus = (status?: boolean) =>
  receiptsBooleanStatuses.find(({ value }) => value === !!status) ||
  UNDEFINED_STATE;

export const computeReceiptOperationType = (
  type?: typeof taxpayersOperationTypes[number]['value'],
) =>
  taxpayersOperationTypes.find(({ value }) => value === type) ||
  UNDEFINED_STATE;
