import axios, { AxiosRequestConfig, CreateAxiosDefaults } from 'axios';
import stores from '@/store/core/RootStore';
import services from '@/api/services';
import { AuthData } from '@/api/services/AuthService/types';
import router from '@/router';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const axiosConfig: CreateAxiosDefaults = {
  baseURL: process.env.VUE_APP_API_URL || '/',
  headers: {
    'Content-Type': 'application/json',
  },
};

const authAxiosConfig: CreateAxiosDefaults = {
  ...axiosConfig,
  baseURL: process.env.VUE_APP_AUTH_API_URL || '/',
};

const selfEmployeeAxiosConfig: CreateAxiosDefaults = {
  ...axiosConfig,
  baseURL: process.env.VUE_APP_SELF_EMPLOYEE_API_URL || '/',
};

const fiscalDataAxiosConfig: CreateAxiosDefaults = {
  ...axiosConfig,
  baseURL: process.env.VUE_APP_FISCAL_DATA_API_URL || '/',
};

const printerProxyAxiosConfig: CreateAxiosDefaults = {
  ...axiosConfig,
  baseURL: process.env.VUE_APP_PRINTER_PROXY_API_URL || '/',
};

export const axiosInstance = axios.create(axiosConfig);
export const axiosAuthInstance = axios.create(authAxiosConfig);
export const axiosSelfEmployeeInstance = axios.create(selfEmployeeAxiosConfig);
export const axiosFiscalDataInstance = axios.create(fiscalDataAxiosConfig);
export const axiosTaxPrinterProxyApi = axios.create(printerProxyAxiosConfig);

export const getBearer = (token?: string) => `Bearer ${token || ''}`;

const refresh = async () => {
  const refreshToken: string = stores.userStore.refreshToken || '';
  const response = await services.auth.refresh({ refreshToken });
  return response.data;
};

let isRefreshing: Promise<AuthData> | null = null;

const REQUEST_INTERCEPTORS = [
  (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers['Authorization'] = getBearer(stores.userStore.token);
    }
    return config;
  },
  (error: any) => error,
];

const RESPONSE_INTERCEPTORS = [
  (res: any) => res,
  async (error: any) => {
    console.log(error);
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      config.isRetry = true;
      try {
        isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        isRefreshing = null;
        stores.userStore.setKey(data);
        return axiosInstance.request(config);
      } catch (error) {
        console.error(error);
        stores.userStore.removeKey();
        await router.push('/auth');
      }
    }
    return Promise.reject(error);
  },
];

axiosInstance.interceptors.request.use(...REQUEST_INTERCEPTORS);
axiosInstance.interceptors.response.use(...RESPONSE_INTERCEPTORS);
axiosSelfEmployeeInstance.interceptors.response.use(...RESPONSE_INTERCEPTORS);
axiosFiscalDataInstance.interceptors.response.use(...RESPONSE_INTERCEPTORS);

export default {
  axiosInstance,
  axiosSelfEmployeeInstance,
  axiosFiscalDataInstance,
  axiosTaxPrinterProxyApi,
};
