import ListStore from '@/store/core/ListStore';
import { action, computed } from 'mobx';
import services from '@/api/services';
import { YearStatistic, YearStatisticRow } from '@/store/types';
import { IncomeAndTaxAnalyticsParams } from '@/api/services/types';
import moment from 'moment';
import { numGroup } from '@/utils/numbers';

export default class TaxpayerYearStatistic extends ListStore<YearStatistic> {
  @computed get processedData(): Record<keyof YearStatisticRow, any>[] | [] {
    if (!this.data) return [];
    const formattedArray = [];

    for (const [key, value] of Object.entries(this.data.incomeTaxes)) {
      formattedArray.push({
        period: key,
        readablePeriod: moment(key).format('MMMM YYYY'),
        ...value,
        partnerPercent: numGroup(value.partnerPercent),
        tax: numGroup(value.tax),
        incomeByPartner: numGroup(value.incomeByPartner),
        income: numGroup(value.income),
      });
    }
    return formattedArray;
  }

  @action.bound async fetch(params: IncomeAndTaxAnalyticsParams) {
    this.isFetching = true;
    try {
      this.data = (
        await services.selfEmployee.getIncomeAndTaxAnalytics(params)
      ).data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
