import { Credentials } from '@/api/services/AuthService/types';
import services from '@/api/services';
import { action, observable, computed } from 'mobx';
import { RootStores } from '@/store/core/RootStore';
import { UserState } from '@/store/types';
import StoreConstructor from '@/store/core/StoreConstructor';

export default class UserStore extends StoreConstructor {
  @observable key: UserState['key'] = null;
  @observable info: UserState['info'] = null;
  @observable error: UserState['error'] = null;
  @observable demoKey: any = '';

  constructor(stores: RootStores) {
    super(stores);
    if (localStorage.getItem('demoKey'))
      this.demoKey = localStorage.getItem('demoKey');
    let key: UserState['key'] = null;
    const lsKey = localStorage.getItem('auth');
    if (lsKey) key = JSON.parse(lsKey);
    this.setKey(key);
  }

  @computed get hasError() {
    return !!this.error;
  }

  @computed get isLoggedIn() {
    return !!this.key;
  }

  @computed get refreshToken() {
    return this.key?.refresh_token;
  }

  @computed get token() {
    return this.key?.access_token;
  }

  @action setError(error: UserState['error'] = null) {
    this.error = error;
  }

  @action setKey(key: UserState['key']) {
    localStorage.setItem('auth', JSON.stringify(key));
    this.key = key;
  }

  @action removeKey() {
    localStorage.removeItem('auth');
    this.key = null;
  }

  @action setInfo(info: UserState['info']) {
    this.info = info;
  }

  @action.bound async LOGIN(props: Credentials) {
    try {
      const { data } = await services.auth.login(props);
      this.setError();
      this.setKey(data);
    } catch (e: any) {
      this.setError(e);
      console.log(e);
    }
  }

  @action.bound async LOGOUT() {
    this.removeKey();
    this.setInfo(null);
  }
}
