import ListStore from '@/store/core/ListStore';
import { action, computed, observable, override } from 'mobx';
import services from '@/api/services';
import { Partners } from '@/store/types';
import { computeBooleanStatus, computePartnerType } from '@/store/mapping';
import { dateFormat, filterFalsy } from '@/utils/filters';
import { PartnersFilters } from '@/api/services/types';
import { Pageable } from '@/types/pagination';
import { i18n } from '@/i18n';

export default class PartnersStore extends ListStore<Pageable<Partners[]>> {
  initialFilters: PartnersFilters = {
    nameOrTin: '',
    registeredAt: undefined,
    type: null,
    isActive: null,
  };
  @observable partnersByTin: {
    label: Partners['name'];
    value: Partners['tin'];
  }[] = [];

  @override
  filters: PartnersFilters = { ...this.initialFilters };

  @action.bound async partnersOptionsListByTin(tin: string) {
    // if (!this.data?.content?.length) return [];
    try {
      const partners = (await services.selfEmployee.getPartnersListByTin(tin))
        .data;
      this.partnersByTin = partners.map(({ name: label, tin: value }) => ({
        label,
        value,
      }));
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  @computed get processedData() {
    return (
      this.data?.content?.map((item: Partners) => {
        const computedType = computePartnerType(item.type);
        const computedStatus = computeBooleanStatus(item.isActive);

        return {
          ...item,
          registeredAt: dateFormat(item.registeredAt, {
            format: 'DD.MM.YYYY',
            addHours: 0,
          }),
          statusLabel: i18n.t(computedStatus?.label),
          statusColor: computedStatus?.color,
          typeLabel: i18n.t(computedType?.label),
          typeColor: computedType?.color,
        };
      }) || []
    );
  }

  @action.bound async fetch() {
    this.isFetching = true;
    try {
      this.data = (
        await services.selfEmployee.getPartnersList({
          ...this.filters,
          ...filterFalsy(this.pageable),
        })
      ).data;
    } catch (e) {
      console.error(e);
      this.clearData();
    } finally {
      this.isFetching = false;
    }
  }
}
