import ListStore from '@/store/core/ListStore';
import { action, computed } from 'mobx';
import services from '@/api/services';
import { Partners } from '@/store/types';
import { computeBooleanStatus, computePartnerType } from '@/store/mapping';
import { dateFormat } from '@/utils/filters';
import { i18n } from '@/i18n';

export default class PartnerCard extends ListStore<Partners> {
  @computed get processedData() {
    if (!this.data) return;
    const computedType = computePartnerType(this.data?.type);
    const computedStatus = computeBooleanStatus(this.data.isActive);

    return {
      ...this.data,
      registeredAt: dateFormat(this.data?.registeredAt, {
        format: 'DD.MM.YYYY',
        addHours: 0,
      }),
      statusLabel: i18n.t(computedStatus.label),
      statusColor: computedStatus.color,
      typeLabel: i18n.t(computedType.label),
      typeColor: computedType.color,
    };
  }

  @action.bound async fetch(tin: string) {
    this.isFetching = true;
    try {
      this.data = (await services.selfEmployee.getPartnerCard(tin)).data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
