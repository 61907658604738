import Vue from 'vue';
import moment from 'moment';

Vue.filter(
  'dateFormat',
  (
    date: string,
    {
      addHours = 3,
      format,
    }: {
      addHours: number;
      format?: string;
    },
  ) => {
    return moment(date)
      .add(addHours, 'hours')
      .format(format || 'DD.MM.YYYY, HH:mm');
  },
);
