import moment from 'moment/moment';

export const dateFormat = (
  date: string | number[],
  {
    addHours = 3,
    format,
  }: {
    addHours: number;
    format?: string;
  },
) => {
  return moment(date)
    .utc()
    .add(addHours, 'hours')
    .format(format || 'DD.MM.YYYY, HH:mm');
};

export const filterFalsy = <
  Obj extends Record<string, any>,
  Key extends keyof Obj,
>(
  obj: Obj,
) => {
  return Object.keys(obj).reduce<Record<string, Obj[Key]>>((acc, key) => {
    if (obj[key]) {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};
