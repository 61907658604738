import { ApiService } from '@/api/services/core/ApiService';
import ENDPOINTS from '@/api/endpoints';
import {
  IncomeAndTaxAnalyticsParams,
  IncomeBaseReceiptParams,
  PartnersFilters,
  ReceiptParams,
  TaxBaseParams,
  TaxpayersFilters,
} from '@/api/services/types';
import {
  axiosFiscalDataInstance,
  axiosInstance,
  axiosSelfEmployeeInstance,
  axiosTaxPrinterProxyApi,
} from '@/api/axios';
import {
  YearStatistic,
  Partners,
  Summary,
  Taxpayers,
  Receipt,
  IncomeBaseReceiptItem,
} from '@/store/types';
import { Pageable, PageableQueryParams } from '@/types/pagination';

export class SelfEmployeeService extends ApiService {
  public getTaxpayersStatistics(
    params: Partial<PageableQueryParams<TaxpayersFilters>>,
  ) {
    return axiosInstance.get<Pageable<Taxpayers[]>>(
      ENDPOINTS.analytics.legalPersonStatistics,
      {
        params,
      },
    );
  }

  public getPartnersList(
    params: Partial<PageableQueryParams<PartnersFilters>>,
  ) {
    return axiosSelfEmployeeInstance.get<Pageable<Partners[]>>(
      ENDPOINTS.stats.getPartnersList,
      { params },
    );
  }

  public getPartnersListByTin(tin: string) {
    return axiosSelfEmployeeInstance.get<Partners[]>(
      ENDPOINTS.stats.getPartnersListByTin,
      { params: { tin } },
    );
  }

  public getIncomeAndTaxAnalytics(params: IncomeAndTaxAnalyticsParams) {
    return axiosInstance.get<YearStatistic>(ENDPOINTS.analytics.incomeAndTax, {
      params,
    });
  }

  public getTaxBase(params: PageableQueryParams<TaxBaseParams>) {
    return axiosInstance.get<Pageable<Receipt[]> & { totalSum: number }>(
      ENDPOINTS.analytics.getTaxBase,
      {
        params,
      },
    );
  }

  public getPartnerCard(tin: string) {
    return axiosSelfEmployeeInstance.get(ENDPOINTS.stats.getPartnerCard(tin));
  }

  public getProfileByTin(tin: string) {
    return axiosSelfEmployeeInstance.get(ENDPOINTS.stats.getProfileByTin(tin));
  }

  public getDetailsByTin(tin: string) {
    return axiosInstance.get(ENDPOINTS.account.taxes.index, {
      params: { tin },
    });
  }

  public getMonthTaxesByTin(params: TaxBaseParams) {
    return axiosInstance.get<{ accruedTotal: number; taxTotal: number }>(
      ENDPOINTS.account.monthActivity.index,
      {
        params,
      },
    );
  }

  public getSummary(params: { from?: string | number; to?: string | number }) {
    return axiosFiscalDataInstance.get<Summary>(
      ENDPOINTS.legal_person.aggregate,
      { params },
    );
  }

  public getIncomeBaseReceipts(
    params: PageableQueryParams<IncomeBaseReceiptParams>,
  ) {
    return axiosFiscalDataInstance.post<
      Pageable<IncomeBaseReceiptItem[] & { totalSum: number }>
    >(ENDPOINTS.findCashVouchers.index, params);
  }

  public getReceipts(
    id: string,
    params: { type: string; startDate: string; endDate: string },
  ) {
    return axiosInstance.get<any>(ENDPOINTS.receipts.income.id(id), {
      params,
    });
  }

  public getReceiptDetails(params: ReceiptParams) {
    console.log(params);
    return axiosTaxPrinterProxyApi.get<Blob>(ENDPOINTS.print.getReceipt, {
      responseType: 'blob',
      params,
    });
  }
}
