import { RouteConfig } from 'vue-router';

import AuthRoutes from '@/router/routes/auth';
import ControlRoutes from '@/router/routes/control';
import SelfEmployeeRoutes from '@/router/routes/selfEmployee';
import ReportsRoutes from '@/router/routes/reports';

const routes: Array<RouteConfig> = [
  AuthRoutes,
  ControlRoutes,
  SelfEmployeeRoutes,
  ReportsRoutes,
  {
    path: '*',
    redirect: '/auth',
  },
];

export default routes;
