import moment from 'moment';
import { i18n } from '@/i18n';

export const capitalize = (value: any) => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value;
};

export function readablePeriod(
  period: Record<string, number | null> | string,
  format?: string | false,
) {
  // moment.locale('ru');

  if (typeof period === 'string')
    return moment(period).format(format || 'MMMM yyyyг');

  const dateArray = Object.values(period).filter(Boolean) as number[];

  const textMonth = moment(dateArray).format(format || 'MMMM yyyyг');
  const numeric = moment(dateArray).format(
    format || dateArray.length === 2 ? 'MM.yyyy' : 'DD.MM.yyyy',
  );

  return { numeric, textMonth };
}

export const translateLabels = <
  T extends Record<string, any> & { label: string },
>(
  array: T[],
) =>
  array.map((item) => ({
    ...item,
    label: i18n.te(item.label) ? i18n.t(item.label) : item.label,
  }));
