import ListStore from '@/store/core/ListStore';
import { action, computed } from 'mobx';
import services from '@/api/services';
import { Taxpayer } from '@/store/types';
import { dateFormat } from '@/utils/filters';
import { computeBooleanStatus, computeType } from '@/store/mapping';
import { i18n } from '@/i18n';

export default class TaxpayerStore extends ListStore<Taxpayer> {
  @computed get processedData() {
    if (!this.data) return;
    const computedType = computeType(this.data.type);
    const computedStatus = computeBooleanStatus(this.data.isActive);

    return {
      ...this.data,
      createDate: dateFormat(this.data.registeredAt, {
        format: 'DD.MM.YYYY',
        addHours: 0,
      }),
      computedAuthority: this.data.authorityCode,
      computedSettingType: this.data.partnerName || i18n.t('types.self'),
      typeLabel: i18n.t(computedType?.label),
      typeColor: computedType?.color,
      statusLabel: i18n.t(computedStatus?.label),
      statusColor: computedStatus?.color,
    };
  }

  @action.bound async fetch(tin: string) {
    this.isFetching = true;
    try {
      this.data = (await services.selfEmployee.getProfileByTin(tin)).data;
    } catch (e) {
      console.error(e);
      this.clearData();
    } finally {
      this.isFetching = false;
    }
  }
}
