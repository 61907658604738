import { RouteConfig } from 'vue-router';

const ReportsView = () => import('@/views/reports.vue');
const ReportsViewIndex = () => import('@/views/reports/index.vue');

export default {
  path: '/reports',
  component: ReportsView,
  meta: {
    name: 'page.reports',
  },
  children: [
    {
      path: '',
      name: 'reports-index',
      component: ReportsViewIndex,
    },
  ],
} as RouteConfig;
