import ListStore from '@/store/core/ListStore';
import { action, computed } from 'mobx';
import services from '@/api/services';
import { dateFormat } from '@/utils/filters';
import { numGroup } from '@/utils/numbers';

export default class TaxpayerReceiptsStore extends ListStore {
  @computed get processedData() {
    if (!this.data) return;
    return this.data.receipts.map((receipt: any) => ({
      ...receipt,
      date: dateFormat(receipt.date, {
        format: 'DD.MM.YYYY',
        addHours: 0,
      }),
      sumReceipt: numGroup(receipt.sumReceipt),
    }));
  }

  @action.bound async fetch(
    id: string,
    params: { type: string; startDate: string; endDate: string },
  ) {
    this.isFetching = true;
    try {
      const { data } = await services.selfEmployee.getReceipts(id, params);
      this.data = data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isFetching = false;
    }
  }
}
