import { action, computed, observable } from 'mobx';
import { PageableQueryParams } from '@/types/pagination';
import StoreConstructor from '@/store/core/StoreConstructor';

export type Sort = {
  prop?: string | null;
  direction: 'asc' | 'desc' | null | undefined;
};

export default abstract class ListStore<T = any> extends StoreConstructor {
  @observable data?: T;
  @observable pageable: PageableQueryParams = {
    size: 20,
    direction: 'desc',
    page: 1,
    sortBy: '',
  };
  @observable pageSizes = [20, 50, 100];
  @observable isFetching = false;
  @observable filters: Record<string, any> = {};
  @observable initialFilters: Record<string, any> = {};

  @computed get sortState(): Sort {
    return {
      prop: this.pageable.sortBy,
      direction: this.pageable.direction,
    };
  }

  @computed get notFalsyParams(): Record<string, any> {
    const notFalsyPageable = Object.entries(this.pageable).filter(
      ([_, value]) => Boolean(value),
    );
    const notFalsyFilters = Object.entries(this.filters).filter(([_, value]) =>
      Boolean(value),
    );

    return {
      ...Object.fromEntries(notFalsyFilters),
      ...Object.fromEntries(notFalsyPageable),
    };
  }

  @computed get currentPage() {
    return this.pageable.page || 1;
  }

  @action.bound setPage(n: number) {
    this.pageable.page = n;
    this.fetch();
  }

  @action.bound setPageable<
    K extends keyof PageableQueryParams,
    V extends PageableQueryParams[K],
  >(filters: Record<K, V>) {
    (Object.entries(filters) as Array<[K, V]>).forEach(
      ([key, value]) => (this.pageable[key] = value),
    );
    this.fetch();
  }

  @action.bound async setSort(sort: Sort) {
    this.pageable.sortBy = sort.prop;
    this.pageable.direction = sort.direction;
    this.pageable.page = 0;
    await this.fetch();
  }

  @action.bound nextPage() {
    this.setPage(this.currentPage + 1);
  }

  @action.bound prevPage() {
    this.setPage(this.currentPage - 1);
  }

  @action.bound setPageSize(n: number) {
    this.pageable.page = 0;
    this.pageable.size = n;
    this.fetch();
  }

  @action setFilter<K extends string>(filters: Record<K, unknown>) {
    Object.entries(filters).forEach(
      ([key, value]) => (this.filters[key] = value),
    );
    // this.fetch();
  }

  async resetData() {
    this.filters = { ...this.initialFilters };
    await this.fetch();
  }

  clearData() {
    this.data = undefined;
  }

  fetch(...params: any): Promise<void> {
    throw new Error('Need to override the Fetch method');
  }
}
